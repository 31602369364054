<template>
  <v-container fluid>
    <!-- START - MAP CONTROL -->
    <v-row style="height: 600px">
      <l-map
        :zoom="zoom"
        :center="initialLocation"
        :options="mapOptions"
        @ready="onReady"
      >
        <!-- <l-tile-layer
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          :options="tileoptions"
        ></l-tile-layer> -->
        <l-control-layers position="topright"></l-control-layers>
        <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
          />

        <!-- main parts of the map overlay -->
        <l-control-zoom position="bottomright"></l-control-zoom>

        <v-marker-cluster @ready="ready">
          <l-marker
            v-for="l in filterDesserts"
            :key="l.id"
            :lat-lng="getlatlng(l)"
            @click="setlala(l.id)"
            @popupopen="setpopup"
            :icon="setIcon(l)"
          >
            <l-popup>
              {{ l }}<br />
              <v-btn :to="{ name: 'ViewIncident', params: { entrycode: l.entrycode } }"
                >View</v-btn
              >
            </l-popup>
          </l-marker>
        </v-marker-cluster>
      </l-map>
    </v-row>
    <!-- END - MAP CONTROL -->

    <!-- START DATATABLE -->
    <v-row>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filterDesserts"
            :items-per-page="15"
            class="elevation-1"
            :search="search"
            show-select
        >
            <template v-for="(col, index) in filters" v-slot:[`header.${index}`]="{ header }">
                {{ header.text }}
                <v-menu :key="index" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="filters[header.value].length ? 'red': ''">
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-list>
                            <v-list-item>
                                <div v-if="filters.hasOwnProperty(header.value)">
                                    <v-autocomplete
                                        multiple
                                        dense
                                        clearable
                                        chips
                                        small-chips
                                        color="light-blue lighten-3"
                                        :items="columnValueList(header.value)"
                                        append-icon="mdi-filter"
                                        v-model="filters[header.value]"
                                        :label="filters[header.value] ? `filter by: ${header.text}` : ''"
                                        hide-details
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small class="caption" v-if="index < 5">
                                                <span>{{ item }}</span>
                                            </v-chip>
                                            <span v-if="index === 5" class="grey--text caption">
                                                (+{{ filters[header.value].length - 5}} others)
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </template>
            <template v-slot:item.TypeOfAnimal="{ item }">
              {{ splitSpecies(item.TypeOfAnimal)}}
            </template>
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th :colspan="headers.length">
                            Use the search to filter fast incidents
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Incidents</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    />
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="viewItem(item)">
                    mdi-eye
                </v-icon>
                <v-icon v-if="countryCheck(item)" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon v-if="countryCheck(item)" small @click="getConfirm(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
    </v-row>
    <!-- END DATATABLE -->


    <!-- START - CONFIRM DELETE DIALOG -->
    <v-dialog
        v-model="deletedialog"
        persistent
        width="350"
        :ripple="foo"
        style="z-index: 1301"
    >
        <v-card>
            <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                <v-toolbar-title>Confirm Incident Delete</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>You are about to delete an Incident. If you are sure,
                    type the following string:</p>
                <p class="justify-center text-center" style="background-color: #e6e6e6">
                    <pre>{{ todelete }}</pre>
                </p>
                <p>in the text input below and press confirm.</p>
                <v-form
                    v-model="valid"
                    ref="confirmform"
                    lazy-validation
                > 
                    <v-text-field
                        v-model="deletecode"
                        :rules="confirmRule"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- END - CONFIRM DELETE DIALOG -->
  </v-container>
</template>

<script>
import { get } from "vuex-pathify";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlZoom,
  LControl,
  LPopup,
  LControlLayers
} from "vue2-leaflet";
import { latLng, icon } from "leaflet";

// missing icons
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// MarkerCluster experiments
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

// Datetime experiments
import { DateTime } from "luxon";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LControl,
    LPopup,
    LControlLayers,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      desserts: [],
      // START MAP DATA
      zoom: 6,
      initialLocation: latLng(38.682613, 22.660929),
      tileoptions: { useCache: true },
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
          name: 'Esri.WorldImagery',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }
      ],
      // Icons for markers on map
      bigbirdicon: icon({
        iconUrl: require('@/assets/markers/bigbird.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      bigbirduniticon: icon({
        iconUrl: require('@/assets/markers/bigbird_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      carnivoresicon: icon({
        iconUrl: require('@/assets/markers/carnivores.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      carnivoresuniticon: icon({
        iconUrl: require('@/assets/markers/carnivores_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      dogicon: icon({
        iconUrl: require('@/assets/markers/dog.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      doguniticon: icon({
        iconUrl: require('@/assets/markers/dog_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      othericon: icon({
        iconUrl: require('@/assets/markers/other.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      otheruniticon: icon({
        iconUrl: require('@/assets/markers/other_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      multipleicon: icon({
        iconUrl: require('@/assets/markers/multiple.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      multipleuniticon: icon({
        iconUrl: require('@/assets/markers/multiple_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      // END MAP DATA
      // START DATATABLE DATA
      selected: [],
      filters: {country: [], region: [], TypeOfAnimal: [], poisonBaitFound: [], autopsyAndMore: [], antipoisonDogUnitUsed: []},
      search: '',
      // END DATATABLE DATA
      // START DELETE DIALOG
      valid: false,
      deletedialog: false,
      foo: false,
      todelete: "",
      deluserid: "",
      deletecode: ""
      // END DELETE DIALOG
    };
  },
  computed: {
      ...get('incidentsnew', [
          'indexes'
      ]),
      superadmin: get('newauth@userProfile.is_superuser'),
      usercountry: get('newauth@userProfile.country'),
      headers () {
          return [
              {
                  text: 'EntryCode',
                  align: 'start',
                  sortable: true,
                  value: 'entrycode'
              },
              {
                  text: "Notification Date",
                  value: "dateOfEvent",
                  sortable: true,
                  sort: (a, b) => {
                    let _a = a
                    let _b = b
                    if (!a) {
                      _a = '01/01/1900'
                    }
                    if (!b) {
                      _b = '01/01/1900'
                    }
                    return DateTime.fromFormat(_a, "dd/MM/yyyy") - DateTime.fromFormat(_b, "dd/MM/yyyy")
                  }
              },
              {
                  text: "Country",
                  value: "country"
              },
              {
                  text: "Region",
                  value: "region"
              },
              {
                  text: "Municipality",
                  value: "municipality"
              },
              {
                  text: "Species found dead",
                  value: "TypeOfAnimal"
              },
              {
                  text: "Poison bait found",
                  value: "poisonBaitFound"
              },
              {
                  text: "Autopsy and/or tox. analyses carried out",
                  value: "autopsyAndMore"
              },
              {
                  text: "Antipoison Dog Unit used",
                  value: "antipoisonDogUnitUsed"
              },
              {
                  text: "Live",
                  value: "live"
              },
              {
                  text: "Actions",
                  value: 'actions',
                  sortable: false
              }
          ]
      },
      filterDesserts () {
          this.desserts.forEach(item => {
              if (!item.country) {
                  item.country = 'n/a'
              }
              if (!item.region) {
                  item.region = 'n/a'
              }
              if (!item.TypeOfAnimal) {
                  item.TypeOfAnimal = 'n/a'
              }
              if (!item.poisonBaitFound) {
                  item.poisonBaitFound = 'n/a'
              }
              if (!item.autopsyAndMore) {
                  item.autopsyAndMore = 'n/a'
              }
              if (!item.antipoisonDogUnitUsed) {
                  item.antipoisonDogUnitUsed = 'n/a'
              }
          })
          return this.desserts.filter((d) => {
              return Object.keys(this.filters).every((f) => {
                  return this.filters[f].length < 1 || this.filters[f].includes(d[f])
              })
          })
      },
      confirmRule() {
        return [
            () => (this.todelete === this.deletecode) || 'Value does not match requested code',
            v => !!v || 'Fill value to confirm'
        ];
      },
  },
  methods: {
    // START MAP METHODS
    setpopup: function (e) {
      this.$nextTick(() => {
        e.target.openPopup();
      });
    },
    onReady() {
      // console.log("Map Loaded");
    },
    setlala(e) {
      this.superlala = e; // FIXME: delete
    },
    ready () {},
    setIcon(item) {
      switch (item.marker) {
        case "bigbird":
          return this.bigbirdicon;
        case "bigbird_unit":
          return this.bigbirduniticon;
        case "carnivores":
          return this.carnivoresicon;
        case "carnivores_unit":
          return this.carnivoresuniticon;
        case "dog":
          return this.dogicon;
        case "dog_unit":
          return this.doguniticon;
        case "other":
          return this.othericon;
        case "other_unit":
          return this.otheruniticon;
        case "multiple":
          return this.multipleicon;
        case "multiple_unit":
          return this.multipleuniticon;
      }
    },
    getlatlng (item) {
        if (!item.lat) {
            return latLng(0,0)
        }
        return latLng(item.lat, item.lng)
    },
    // END MAP METHODS
    initialize () {
        this.desserts = this.indexes
    },
    columnValueList(val) {
        return this.desserts.map((d) => d[val])
    },
    viewItem (item) {
        this.$router.push({name: 'ViewIncident', params: {'entrycode': item.entrycode}})
    },
    editItem (item) {
        this.$router.push({name: 'Informant', params: {'entrycode': item.entrycode }})
    },
    splitSpecies (item) {
      if(!item) return 'n/a'
        if (item == '#') {
          return 'n/a'
        }
        return item.split('#').join(', ')
    },
    // START CONFIRM DELETE METHODS
    getConfirm (item) {
        this.todelete = `delete ${item.entrycode}`
        this.delentrycode = item.entrycode
        this.deletedialog = true
    },
    delcancel () {
        this.$refs.confirmform.resetValidation()
        this.deletecode = ""
        this.todelete= ""
        this.delentrycode = ""
        this.deletedialog = false
    },
    delconfirm () {
        if (this.$refs.confirmform.validate()) {
            this.deletecode = ""
            // delete the user
            this.$store.dispatch('incidentsnew/deleteIncident', this.delentrycode)
                .then(console.log(true))
                .catch(e => {
                    console.log(e)
                })
        } else {
            this.foo = true
            console.log('the validation is not correct')
        }
        this.deletecode = ""
        this.delentrycode = ""
        this.$refs.confirmform.resetValidation()
        this.deletedialog = false
    },
    // END CONFIRM DELETE METHODS
    countryCheck (item) {
      if (this.superadmin) return true

      if (this.usercountry === item.country ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
  created () {
      this.initialize()
  }
};
</script>


<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.map-button {
  background: linear-gradient(
    0deg,
    rgba(126, 176, 159, 1) 0%,
    rgba(135, 196, 188, 1) 100%
  );
  border-color: #7eb09f;
  border: 2px solid;
}

.map-button2 {
  background: linear-gradient(
    0deg,
    rgba(255, 230, 54, 1) 0%,
    rgba(248, 255, 142, 1) 100%
  );
  border-color: #efdd00;
  border: 2px solid;
}

.filtermenu {
  z-index: 1305;
  background-color: #f1ebe0;
}

.cardtitle {
  background-color: #d9c7a3;
}
</style>